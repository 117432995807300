<template>
  <div>
    <div id="faq-page" class="landing-page pre-formatted">
      <!-- JUMBOTRON -->
      <div class="faq-jumbotron">
        <div class="ph-banner faq-jumbotron-content text-center lg:p-32 md:p-24 sm:p-16 py-16 px-8"
             :style="{ backgroundImage: `url(${heroBannerContent.image})` }">
          <div class="ph-banner-inner-content flex items-center justify-center flex-wrap h-full">
            <div>
              <h1 class="mb-10 text-white text-bold w-full flex-center">{{ heroBannerContent.title }}</h1>
              <p>{{
                  heroBannerContent.description
                }}</p>
              <vs-button @click="buttonHandler('heroBanner1')" align="center">{{
                  heroBannerContent.buttonText
                }}
              </vs-button>
            </div>
          </div>
        </div>
      </div>
      <div class="image-desc">
        <div class="container">
          <div class="single-img-desc flex flex-wrap items-center mt-20 mb-20">
            <div class="image-wrap">
              <video width="460" height="500" controls autoplay v-if="whyChooseUsContent.video">
                <source :src="whyChooseUsContent.video" type="video/mp4">
                <source :src="whyChooseUsContent.video" type="video/ogg">
                Your browser does not support the video tag.
              </video>
              <img :src="whyChooseUsContent.image" v-else>
            </div>
            <div class="desc-wrap">
              <h3>{{ whyChooseUsContent.title }}</h3>
              <p>{{
                  whyChooseUsContent.description
                }}</p>
              <vs-button @click="buttonHandler('whyChooseUsContent')" align="center">{{
                  whyChooseUsContent.buttonText
                }}
              </vs-button>
            </div>
          </div>
          <div class="single-img-desc flex flex-wrap items-center mb-20">
            <div class="image-wrap">
              <img :src="whyChooseUsContent2.image">
            </div>
            <div class="desc-wrap">
              <h3>{{ whyChooseUsContent2.title }}</h3>
              <p>{{ whyChooseUsContent2.description }}</p>
              <vs-button @click="buttonHandler('whyChooseUsContent2')" align="center">
                {{ whyChooseUsContent2.buttonText }}
              </vs-button>
            </div>
          </div>
        </div>
      </div>
      <div class="how-can-we-help text-center">
        <div class="container">
          <div class="help-title">
            <h2>{{ howCanWeHelp.title }}</h2>
            <p>{{ howCanWeHelp.description }}</p>
          </div>
          <div class="help-points flex flex-wrap items-center justify-between">
            <div class="help" v-for="(usp, index) in uspList" :key="index">
              <div class="help-icon">
                <img :src="usp.icon">
              </div>
              <h3>{{ usp.title }}</h3>
              <p>{{ usp.description }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="testimonials text-center">
        <div class="container">
          <h2>Testimonials</h2>
          <div class="tm-wrapper">
            <swiper class="px-20" :options="swiperOption" :dir="$vs.rtl ? 'rtl' : 'ltr'" :key="$vs.rtl">
              <div class="swiper-button-prev" slot="button-prev"></div>

              <swiper-slide v-for="(testimonial, key) in testimonials" :key="key">
                <div class="tm-item">
                  <div class="tm-img">
                    <img
                      :src="testimonial.profileImage" :alt="testimonial.fullName">
                  </div>
                  <div class="tm-details">
                    <p>{{testimonial.description}}</p>
                    <span>
                    {{ `${testimonial.title ? testimonial.title : ''} ${testimonial.fullName}` }}
                    <template v-if="testimonial.sourceFrom">
                    <br>
                    {{ testimonial.sourceFrom ? "("  + testimonial.sourceFrom + ")" : '' }}
                    </template>

                    </span>
                  </div>
                </div>
              </swiper-slide>

              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import {mapActions} from "vuex";
import Vue from "vue";
import VueScrollTo from "vue-scrollto";
import 'swiper/dist/css/swiper.min.css'
import {swiper, swiperSlide} from 'vue-awesome-swiper'
import {formattedUrl} from "../../../helpers/general";

Vue.use(VueScrollTo);

export default {
  name: "home-page",
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 40,
        // init: false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 40
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20
          }
        }
      },
      heroBannerContent: {
        image: '',
        buttonText: '',
        buttonUrl: '',
        title: '',
        description: '',
      },
      testimonials: [],
      whyChooseUsContent: {
        image: '',
        video: '',
        buttonText: '',
        buttonUrl: '',
        title: '',
        description: '',
      },
      howCanWeHelp: {
        image: '',
        buttonText: '',
        buttonUrl: '',
        title: '',
        description: '',
      },
      whyChooseUsContent2: {
        image: '',
        buttonText: '',
        buttonUrl: '',
        title: '',
        description: '',
      },
      uspList: [],
    };
  },
  methods: {
    ...mapActions("pages", [
      "fetchContentBySlug",
      "fetchUSPList",
    ]),
    ...mapActions("setting", [
      "fetchLandingTestimonialList"
    ]),
    formattedUrl,
    buttonHandler(key) {
      if (key === "heroBanner1") {
        this.$router.push(this.heroBannerContent.buttonUrl);
      }
      if (key === "whyChooseUsContent") {
        this.$router.push(this.whyChooseUsContent.buttonUrl);
      }
      if (key === "whyChooseUsContent2") {
        this.$router.push(this.whyChooseUsContent2.buttonUrl);
      }
    },
  },
  mounted() {
    if (this.$router.currentRoute['hash']) {
      VueScrollTo.scrollTo(this.$router.currentRoute['hash'], 500);
    }
  },

  created() {
    //hero banner section
    this.fetchContentBySlug("landing-page-banner-section-image").then(res => {
      this.heroBannerContent.image = res.data.data.content;
    });
    this.fetchContentBySlug("landing-page-banner-section-button").then(res => {
      this.heroBannerContent.buttonText = res.data.data.buttonText;
      this.heroBannerContent.buttonUrl = res.data.data.buttonUrl;
    });
    this.fetchContentBySlug("landing-page-banner-section-title").then(res => {
      this.heroBannerContent.title = res.data.data.content;
    });
    this.fetchContentBySlug("landing-page-banner-section-description").then(res => {
      this.heroBannerContent.description = res.data.data.content;
    });

    //Why choose us section
    this.fetchContentBySlug("landing-page-video-and-content-section-title").then(res => {
      this.whyChooseUsContent.title = res.data.data.content;
    });
    this.fetchContentBySlug("landing-page-video-and-content-section-description").then(res => {
      this.whyChooseUsContent.description = res.data.data.content;
    });
    this.fetchContentBySlug("landing-page-video-and-content-section-image").then(res => {
      this.whyChooseUsContent.image = res.data.data.content;
    });
    this.fetchContentBySlug("landing-page-video-and-content-section-video").then(res => {
      this.whyChooseUsContent.video = res.data.data.content;
    });


    this.fetchContentBySlug("landing-page-video-and-content-section-button").then(res => {
      this.whyChooseUsContent.buttonText = res.data.data.buttonText;
      this.whyChooseUsContent.buttonUrl = res.data.data.buttonUrl;
    });

    this.fetchContentBySlug("landing-page-image-and-content-section-title").then(res => {
      this.whyChooseUsContent2.title = res.data.data.content;
    });
    this.fetchContentBySlug("landing-page-image-and-content-section-description").then(res => {
      this.whyChooseUsContent2.description = res.data.data.content;
    });
    this.fetchContentBySlug("landing-page-image-and-content-section-image").then(res => {
      this.whyChooseUsContent2.image = res.data.data.content;
    });
    this.fetchContentBySlug("landing-page-image-and-content-section-button").then(res => {
      this.whyChooseUsContent2.buttonText = res.data.data.buttonText;
      this.whyChooseUsContent2.buttonUrl = res.data.data.buttonUrl;
    });


    //How we can help section

    this.fetchContentBySlug("landing-page-how-can-we-help-section-description").then(res => {
      this.howCanWeHelp.description = res.data.data.content;
    });

    this.fetchContentBySlug("landing-page-how-can-we-help-section-title").then(res => {
      this.howCanWeHelp.title = res.data.data.content;
    });

    //USP List
    this.fetchUSPList().then(res => {
      this.uspList = res.data;
    });

    //Testimonials List
    this.fetchLandingTestimonialList().then(res => {
      this.testimonials = res.data;
    });

  }
};
</script>
<style lang="scss">
#faq-page {
  .faq-jumbotron-content {
    background-size: cover;
  }

  .faq-bg {
    background-color: #fff;
  }
}

.pre-formatted {
  white-space: pre-wrap;
}

#banner-page {
  .faq-jumbotron-content {
    background-size: cover;
    background-color: #74CAC2;
  }

  h1 {
    @media(min-width: 768px) {
      font-size: 50px;
    }
  }

  p {
    font-size: 18px;
  }

  .faq-bg {
    background-color: cyan;
  }
}

#package-section {
  padding: 0 40px 40px;

  .package-card {
    min-height: 400px;
    margin: 20px
  }

  .package-card-advanced {
    min-height: 440px;
    position: relative;

    .value {
      background-color: #2898D5;
      min-height: 50px;
      padding: 10px;
      position: relative;
      text-align: center;
      border-radius: 5px;

      @media(min-width: 768px) {
        position: absolute;
        top: -40px;
        left: 0;
        right: 0;
      }
    }
  }
}

.heading {
  text-align: center;
}

#contact-section {

  .contact-form-section {
    @media (min-width: 768px) {
      padding: 0 0 0 60px;
    }
  }
}

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #ffff;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.round {
  position: relative;
  padding-left: 35px;
}

.round .material-icons {
  color: #74CAC2;
  font-size: 25px;
  position: absolute;
  left: 0;
}

.last-child {
  border-left: 1px solid #A5A5A5;
}

.contact-section-wrap {
  background-color: #FFF;
}

.contact-section-wrap, .faq-wrap, .text-section {

  h1 {
    @media(min-width: 768px) {
      font-size: 50px;
      line-height: 1;
    }
  }

  p {
    @media(min-width: 768px) {
      font-size: 18px;
    }
  }
}

.vs-input--input.hasIcon:focus + .vs-input--placeholder, .vs-con-input-label.is-label-placeholder.isFocus .vs-input--placeholder, .vs-input--input.hasValue + .vs-placeholder-label, .vs-input--input:focus + .vs-placeholder-label {
  padding-left: 15px !important;
}

.vs-con-textarea {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.faq-wrap {
  .td-check {
    display: none;
  }

  .vs-con-table .vs-con-tbody {
    background: none;
    border: none;
  }

  .vs-table--tbody-table tr {
    background: none;
  }

  .vs-con-table .vs-con-tbody .vs-table--tbody-table .tr-values .vs-table--td {
    font-size: 20px;
    color: #27378E;
    padding: 30px 0;
  }

  .vs-table--tbody-table .tr-expand td {
    padding: 0 30px 30px;
    border: none;
  }

  .tr-expand td .content-tr-expand, .tr-expand td .tr-expand--close {
    justify-content: start;
    border: none;
    padding: 0;
  }

  tr.tr-values.vs-table--tr.tr-table-state-null.selected.tr-expandedx td {
    border-bottom-color: transparent;
  }
}

</style>

<template>
  <div class="layout--full-page">
    <front-header />
    <Landing />
    <front-footer />
  </div>
</template>

<script>
import Landing from "@/views/pages/cms/LandingPage.vue";
import FrontFooter from "@/layouts/components/FrontFooter.vue";
import FrontHeader from "@/layouts/components/FrontHeader.vue";

export default {
  components: {
    Landing,
    FrontFooter,
    FrontHeader
  },
  data() {
    return {};
  }
};
</script>
